import React, { useState } from 'react';
import { Stack, ImageList, ImageListItem, Divider, Chip, Box, Grid, Typography, TextField, } from '@mui/material';

import PropTypes from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ImgDialog from './ImgDialog/ImgDialog';
import CheckoutButton from './CheckoutButton';
import QuantityAdjuster from './QuantityAdjuster';
import { formatTime, calculateEndTime } from './commonUtils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimeField } from '@mui/x-date-pickers';
import SelectTimeDrawer from './SelectTimeDrawer';
import { useTranslation } from 'react-i18next';
import airyVibeTheme from '../airyVibeTheme';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function ServiceDisplayBox({ serviceData, studioName, openHours, studioBookedTime }) {
    const { t } = useTranslation();
    // location tab related
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        if (newValue === value) return;
        setValue(newValue);
        setPriceOptionSelected(0);
        setQuantity(1);
    };
    const [orderNote, setOrderNote] = useState("");
    // price option selection
    const [priceOptionSelected, setPriceOptionSelected] = useState(0);
    function handleChipClick(price_index) {
        setPriceOptionSelected(price_index);
        console.log("ui_clicked: price option chip index:", price_index);
        setQuantity(1);
    }
    // quantity and handler
    const [quantity, setQuantity] = useState(1);
    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    // image dialog related
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageDialogSrc, setimageDialogSrc] = useState("");
    let imgDialogHandleClose = () => { setImageDialogOpen(false) };
    const callImageDialog = (event) => {
        if (event.target.tagName !== 'IMG') return;
        if (imageDialogOpen) return;
        const src = event.target.src;
        setimageDialogSrc(src);
        setImageDialogOpen(true);
    };
    // reservation date and time
    const [reservationTime, setReservationTime] = useState(null);

    return (
        <div className='ServiceDisplayBox'>
            <ImgDialog src={imageDialogSrc} open={imageDialogOpen} handleClose={imgDialogHandleClose} />
            <Stack sx={{ px: 1 }} className='pageContent'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="basic tabs"
                        variant="scrollable"
                        scrollButtons
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        }}
                    >
                        {serviceData.map((service, service_index) => (
                            <Tab key={`service-tab-${service_index}`} label={service['serviceName']}
                                sx={{
                                    color: 'pink',
                                    "&.Mui-selected": {
                                        color: "pink"
                                    }
                                }} />
                        ))}
                    </Tabs>
                </Box>
                <Stack sx={{ px: 1 }} className='tabPanelContent'>
                    {serviceData.map((service, service_index) => (
                        <TabPanel key={`service-tab-${service_index}`} value={value} index={service_index}>
                            <Grid container>
                                <Grid item xs={12} md={7}>
                                    <ImageList cols={1} variant='standard'>
                                        {service.images.map((item) => (
                                            <ImageListItem key={item.url}>
                                                <img
                                                    width={'100%'}
                                                    height='auto'
                                                    src={item.url}
                                                    alt={item.name}
                                                    loading="lazy"
                                                    onClick={callImageDialog}
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Box id="serviceDescriptionBox" sx={{ padding: { md: '16px', lg: '32px' } }} >
                                        <Typography variant='h6' my={2}> {service.serviceName}</Typography>
                                        <Box id="priceOptionBox">
                                            <Box id="priceOptionChipBox" display='flex' flexWrap="wrap" gap={1}>
                                                {service.prices.map((price_option, price_index) =>
                                                (<Chip
                                                    key={`price-option-chip-box-${price_index}`}
                                                    label={price_option.priceName}
                                                    variant={priceOptionSelected === price_index ? "contained" : "outlined"}
                                                    color="pink"
                                                    sx={{
                                                        "&.MuiChip-contained:hover": {
                                                            bgcolor: `${airyVibeTheme.palette.pink.main} !important`,
                                                            color: `${airyVibeTheme.palette.white} !important`,
                                                            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.3)',
                                                            transform: 'scale(1.04)',
                                                        },
                                                    }}
                                                    onClick={() => { handleChipClick(price_index) }} />)
                                                )}
                                            </Box>
                                            {service.prices[priceOptionSelected] &&
                                                <Box id="priceDetailBox">
                                                    <Box id="priceAndLengthRowBox" my={2}>
                                                        <Typography component="span" >{t('studio.price')}: ${service.prices[priceOptionSelected].price / 100}</Typography>
                                                        <Box component="span" mx={2}>
                                                        </Box>
                                                        {service.prices[priceOptionSelected].timeReserve &&
                                                            <Typography component="span">{t('studio.serviceLength')}: {formatTime(service.prices[priceOptionSelected].reserveTimeInMinute)}</Typography>
                                                        }
                                                    </Box>
                                                    <Box id="descriptionRowBox" my={2}>
                                                        {service.prices[priceOptionSelected].description.split('\n').map((content, lineIndex) => (
                                                            <Typography key={`service-description-line-${lineIndex}`}>
                                                                {lineIndex === 0 && t('studio.servicePriceDescription') + ": "} {content}
                                                            </Typography>
                                                        ))}
                                                    </Box>
                                                    {service.prices[priceOptionSelected].isAdjustable &&
                                                        <Box id='QuantityAdjustBox'>
                                                            <QuantityAdjuster quantity={quantity} setQuantity={handleQuantityChange} />
                                                            <p>{t('studio.totalAmount')}: ${(service.prices[priceOptionSelected].price / 100 * quantity).toFixed(2)}</p>
                                                        </Box>
                                                    }
                                                    {service.prices[priceOptionSelected].timeReserve &&
                                                        <Box>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                {reservationTime !== null &&
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={6} mt={2}>
                                                                            <DateTimeField
                                                                                label="From"
                                                                                value={reservationTime}
                                                                                disabled
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6} mt={2}>
                                                                            <DateTimeField
                                                                                label="To"
                                                                                value={calculateEndTime(reservationTime, service.prices[priceOptionSelected].reserveTimeInMinute * (service.prices[priceOptionSelected].isAdjustable ? quantity : 1))}
                                                                                disabled
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                <SelectTimeDrawer
                                                                    openHours={openHours}
                                                                    reservationLength={service.prices[priceOptionSelected].reserveTimeInMinute * (service.prices[priceOptionSelected].isAdjustable ? quantity : 1)}
                                                                    onTimeChange={(newTime) => setReservationTime(newTime)}
                                                                    studioBookedTime={studioBookedTime}
                                                                />
                                                            </LocalizationProvider>
                                                            <Divider variant="middle" style={{ marginBottom: "16px" }}></Divider>
                                                        </Box>
                                                    }
                                                    <Box display={'flex'} justifyContent={'center'} mb={2}>
                                                        <TextField
                                                            size='small'
                                                            label={t('studio.orderNotes')}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            value={orderNote}
                                                            onChange={(e) => {
                                                                setOrderNote(e.target.value)
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box display={'flex'} justifyContent={'center'}>
                                                        <CheckoutButton
                                                            priceIds={[service.prices[priceOptionSelected].price_id]}
                                                            quantities={[quantity]}
                                                            studioName={studioName}
                                                            orderNote={orderNote}
                                                            time_reserve_is_required={service.prices[priceOptionSelected].timeReserve}
                                                            time_reserved_start={reservationTime}
                                                            time_reserved_end={calculateEndTime(reservationTime, service.prices[priceOptionSelected].reserveTimeInMinute * (service.prices[priceOptionSelected].isAdjustable ? quantity : 1))} />
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))}
                </Stack>
            </Stack>
        </div >
    );
}
