import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Box, TextField, Avatar } from '@mui/material';

import { getUserAndData } from '../User';
import { UploadWithMyCropper } from '../MyCropper';
import { useTranslation } from 'react-i18next';
import { uploadAvatar, getUsersAvatars, updateUserDataByColumn } from '../apis';
import { uploadFileToUrl, base64ToFile } from '../commonUtils';
import { useSnackbar } from '../providers/SnackbarProvider';

export default function ProfileNameAvatar() {
    const callSnackbar = useSnackbar();
    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [isPhotographer, setIsPhotographer] = useState(true);
    const [fileType, setFileType] = useState('');

    const [avatarFile, setAvatarFile] = useState(null);
    const [avatarFileBase64, setAvatarFileBase64] = useState('');
    const [isLocked, setIsLocked] = useState(false);

    const [lastDisplayName, setLastDisplayName] = useState('');
    const [userDisplayName, setUserDisplayName] = useState('');
    const [isDisplayNameButtonLocked, setIsDisplayNameButtonLocked] = useState(false);
    const [displayNameError, setDisplayNameError] = useState('');

    const loadDataRef = useRef(false);

    async function loadData() {
        const { user, userData } = await getUserAndData();
        setUser(user);
        setUserDisplayName(userData.displayName ?? '');
        setLastDisplayName(userData.displayName ?? '');
        setIsPhotographer(userData.userType === 'photographer');
        const avatarResult = JSON.parse(await getUsersAvatars([user.username]));
        setAvatarFileBase64(avatarResult?.body?.avatars[0]);
    }

    useEffect(() => {
        if (loadDataRef.current) return;
        loadDataRef.current = true;
        loadData();
    }, []);

    const validateDisplayName = (name) => {
        if (name.length < 2 || name.length > 20) {
            setDisplayNameError(t('profile.userNameLengthError'));
            return false;
        }
        setDisplayNameError('');
        return true;
    };

    const handleDisplayNameChange = (e) => {
        const newName = e.target.value;
        setUserDisplayName(newName);
        validateDisplayName(newName);
    };

    const handleSaveAvatar = async () => {
        let response = await uploadAvatar(fileType);
        response = JSON.parse(response);
        if (response.statusCode === 200 && response.body && response.body.uploadUrl) {
            const imageUrl = response.body.uploadUrl.imageUrl;
            const resultIsSuccess = await uploadFileToUrl(avatarFile, fileType, imageUrl);
            if (resultIsSuccess) {
                callSnackbar('Avatar uploaded successfully', 5000, 'success');
                loadData().then(() => {
                    setAvatarFile(null);
                })
            } else {
                callSnackbar('Avatar uploaded failed', 30000, 'error');
            }
        } else {
            callSnackbar('Avatar uploaded failed', 30000, 'error');
        }
    };

    const handleSaveDisplayName = async () => {
        const newName = userDisplayName;
        const apiResponse = JSON.parse(await updateUserDataByColumn(user, newName, 'displayName'));
        if (apiResponse['statusCode'] === 200) {
            callSnackbar('User name updated successfully', 5000, 'success');
            setLastDisplayName(newName);
        } else {
            callSnackbar('User name updated failed', 30000, 'error');
        }
    };

    if (!user) return <Typography>Loading...</Typography>;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
                {avatarFileBase64
                    ?
                    <Avatar src={avatarFileBase64} alt='avatar file selected' sx={{ width: 100, height: 100 }} />
                    :
                    <Avatar src={user.avatarUrl} alt={user.username} sx={{ width: 100, height: 100 }} />
                }
            </Box>
            <Box width={360} maxWidth={'100%'} display={'flex'} flexDirection={'row'}>
                <UploadWithMyCropper
                    source={"https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"}
                    aspectRatio={2 / 2}
                    width='80%'
                    callback={(cropped) => {
                        setAvatarFileBase64(cropped);
                        const croppedFile = base64ToFile(cropped);
                        setFileType(croppedFile.type);
                        setAvatarFile(croppedFile);
                    }}
                    disabled={isLocked}
                />
                <Button
                    sx={{ marginX: 1 }}
                    variant="contained"
                    color="pink"
                    disabled={!avatarFile || isLocked}
                    onClick={async () => {
                        setIsLocked(true);
                        await handleSaveAvatar();
                        setIsLocked(false);
                    }}
                >
                    {t('profile.uploadAvatar')}
                </Button>
            </Box>
            <Box width={360} maxWidth={'100%'} display={'flex'} flexDirection={'row'}>
                <TextField
                    label={t('profile.userName')}
                    value={userDisplayName}
                    onChange={handleDisplayNameChange}
                    disabled={isPhotographer || isDisplayNameButtonLocked}
                    size='small'
                    error={!!displayNameError}
                    helperText={displayNameError}
                    inputProps={{ minLength: 2, maxLength: 20 }}
                />
                {userDisplayName !== lastDisplayName && (
                    <Button
                        sx={{ marginX: 1 }}
                        variant="contained"
                        color="pink"
                        disabled={isDisplayNameButtonLocked || !!displayNameError}
                        onClick={async () => {
                            if (validateDisplayName(userDisplayName)) {
                                setIsDisplayNameButtonLocked(true);
                                await handleSaveDisplayName();
                                setIsDisplayNameButtonLocked(false);
                            }
                        }}
                    >
                        {t('profile.saveUserName')}
                    </Button>
                )}
            </Box>
            {isPhotographer && (
                <Typography>{t('profile.photographerMustNotChangeName')}</Typography>
            )}
        </Box>
    );
}
