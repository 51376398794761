import React, { useEffect, useState } from 'react';
import {
    Stack, Button, Typography, Box, Avatar, IconButton, Menu, MenuItem, ListItemIcon,
    Tooltip, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import logo192 from '../img/logo29x23.svg';
import "./Navbar.css";
import { Auth, Hub, Logger } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { getUserAndData } from '../User';
import { getStudioLogoTime } from '../apis';
import CopyTypography from '../../widgets/CopyTypography/CopyTypography';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../providers/SnackbarProvider';
import { pink } from '../../airyVibeTheme';

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import UserAvatar from '../UserAvatar';

const dayToIndex = { 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Sunday': 7 };

async function signOut() {
    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
}
const logger = new Logger('Navigator');

const StudioInfoInNav = ({ currentRoute }) => {
    const { t } = useTranslation();
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 768);
    const [studioName, setStudioName] = useState('');
    const [timeData, setTimeData] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const sortedAndGroupedData = (timeData) => {
        if (timeData === undefined) return <>暂未提供/No data provided</>
        const sortedData = timeData.sort((a, b) => dayToIndex[a.day] - dayToIndex[b.day]);
        const groupedData = sortedData.reduce((acc, { day, start, end }) => {
            (acc[day] = acc[day] || []).push(`${start} to ${end}`);
            return acc;
        }, {});

        return Object.entries(groupedData).map(([day, times]) => (
            <div key={day}>
                <strong>{day}:</strong>
                <ul>
                    {times.map((time, index) => (
                        <li key={index}>{time}</li>
                    ))}
                </ul>
            </div>
        ));
    };
    let requested = false;
    useEffect(() => {
        if (smallScreen) return;
        if (requested) return;
        requested = true;
        loadLogoTime()
    }, [])
    async function loadLogoTime() {
        const studioName = currentRoute.substring('/at/'.length)
        setStudioName(studioName)
        const logoAndTime = JSON.parse(JSON.parse(await getStudioLogoTime(studioName))).studioData;
        setTimeData(sortedAndGroupedData(logoAndTime.timeData))
        setLogoUrl(logoAndTime.logo)
    }
    // popover control

    const [openTimeDialog, setOpenTimeDialog] = React.useState(false);
    const handleClickOpenTimeDialog = () => {
        setOpenTimeDialog(true);
    };
    const handleCloseTimeDialog = () => {
        setOpenTimeDialog(false);
    };

    const [openCustomerServiceDialog, setOpenCustomerServiceDialog] = React.useState(false);
    const handleClickOpenCustomerServiceDialog = () => {
        setOpenCustomerServiceDialog(true);
    };
    const handleCloseCustomerServiceDialog = () => {
        setOpenCustomerServiceDialog(false);
    };
    if (smallScreen) return (<></>)
    return (
        <div>
            <Box display="flex" alignItems="center">
                <Button
                    onClick={handleClick} style={{ textTransform: 'none', display: 'flex', gap: 4, color: 'black' }}>
                    <Avatar src={logoUrl}>
                    </Avatar>
                    <Typography>{decodeURIComponent(studioName)}</Typography>
                </Button>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    display="flex"
                    alignItems="center"
                    onClick={() => { handleClickOpenCustomerServiceDialog(); handleClose(); }}>
                    <ListItemIcon>
                        <ContactMailIcon fontSize="small" />
                    </ListItemIcon>
                    {t("studio.contactSupport")}
                </MenuItem>
                <MenuItem
                    display="flex"
                    alignItems="center"
                    onClick={() => { handleClickOpenTimeDialog(); handleClose(); }}>
                    <ListItemIcon>
                        <ScheduleIcon fontSize="small" />
                    </ListItemIcon>
                    {t("studio.workingHours")}
                </MenuItem>
            </Menu>

            <Dialog
                open={openCustomerServiceDialog}
                onClose={() => { handleCloseCustomerServiceDialog(); handleClose(); }}
            >
                <DialogTitle>
                    {t('studio.contactSupport')}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('navbar.contactPlatformNotice')}
                    </Typography>
                    <CopyTypography copyContent={t('constant.airyvibeEmail')}>
                        Email: {t('constant.airyvibeEmail')}
                    </CopyTypography>
                    <CopyTypography copyContent={t('constant.ronWechat')}>
                        {t('constant.wechat')}: {t('constant.ronWechat')}
                    </CopyTypography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleCloseCustomerServiceDialog(); handleClose(); }} autoFocus>
                        {t('constant.close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openTimeDialog}
                onClose={() => { handleCloseTimeDialog(); handleClose(); }}
            >
                <DialogTitle>
                    {decodeURIComponent(studioName) + ' ' + t('studio.workingHours')}
                </DialogTitle>
                <DialogContent
                    sx={{ minWidth: '400px' }}>
                    {timeData}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleCloseTimeDialog(); handleClose(); }} autoFocus>
                        {t('constant.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
const UserAvatarWithMenu = () => {
    const callSnackbar = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [username, setUsername] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let requested = false;
    const [userType, setUserType] = useState('');
    useEffect(() => {
        if (requested) return;
        requested = true;
        async function loadUserType() {
            const userAndData = await getUserAndData();
            const userData = userAndData.userData;
            if (userData) {
                setUserType(userData.userType);
                setUsername(userData.Username);
            }
            if (!userData.userType) {
                console.log("this user does not have userType set, redirect to registration.");
                callSnackbar(t('navbar.redirectingToRegistration'), 3000, 'info');
                const timer = setTimeout(() => {
                    navigate('/registration');
                }, 0);
                return () => clearTimeout(timer);
            }
            else if (userData.userType === 'photographer' && !("studioRegister" in userData)) {
                console.log("User is a photographer but has not registered a studio, redirecting to studio registration.");
                callSnackbar(t('navbar.redirectingToStudioRegistration'), 3000, 'info');
                const timer = setTimeout(() => {
                    navigate('/studioRegister');
                }, 0);
                return () => clearTimeout(timer);
            }
            else if (userData.userType === 'customer' && !("displayName" in userData)) {
                console.log("Please set your user name to continue your journey.");
                callSnackbar(t('navbar.redirectingToProfile'), 3000, 'info');
                const timer = setTimeout(() => {
                    navigate('/profile');
                }, 0);
                return () => clearTimeout(timer);
            }
        }
        loadUserType();
    }, [])
    return (
        <div>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}>
                    <UserAvatar username={username} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => navigate('/order')}>
                    <ListItemIcon>
                        <ListAltIcon fontSize="small" />
                    </ListItemIcon>
                    {t('navbar.orders')}
                </MenuItem>
                {userType === 'customer' &&
                    <MenuItem>
                        <ListItemIcon>
                            <FavoriteIcon fontSize="small" />
                        </ListItemIcon>
                        {t('navbar.favorite')} {"(not developed)"}
                    </MenuItem>
                }
                {userType === 'photographer' &&
                    <MenuItem onClick={() => navigate('/mystudio')}>
                        <ListItemIcon>
                            <CoPresentIcon fontSize="small" />
                        </ListItemIcon>
                        {t('navbar.myStudio')}
                    </MenuItem>
                }
                <MenuItem onClick={() => navigate('/profile')}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    {t('navbar.profileSetting')}
                </MenuItem>
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('navbar.logout')}
                </MenuItem>
            </Menu>
        </div>
    );
};

const Navbar = () => {
    const [user, setUser] = useState(null);
    const currentRoute = window.location.pathname;
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        loadUser();
        const authListener = (data) => {
            logger.info('on Auth event', data.payload);
            loadUser();
        };
        Hub.listen('auth', authListener);
        return () => {
            Hub.remove('auth', authListener);
        };
    }, []);

    const loadUser = () => {
        Auth.currentAuthenticatedUser()
            .then(user => setUser(user))
            .catch(err => setUser(null));
    };

    return (
        <Box className='navbar'
            height={'56px'}
            sx={{
                background: `linear-gradient(to bottom, ${pink[5]}, ${pink[7]})`
            }}>
            <Box display={'flex'}>
                <Box justifyContent="flex-start">
                    <Button style={{ color: 'inherit', textDecoration: 'none' }} onClick={() => navigate('/')}>
                        <img src={logo192} height={'40px'} width={'40px'} alt='logo'></img>
                        <Typography ml={1} fontSize={{ xs: 24, sm: 40, lg: 40 }} lineHeight={1} fontFamily={"Baloo2-ExtraBold"}>
                            {"AiryVibe"}
                        </Typography>
                    </Button>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
                    <Button onClick={() => navigate('/about')} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography fontSize={{ xs: 12, sm: 18, lg: 24 }} variant="text">
                            {t('navbar.about')}
                        </Typography>
                    </Button>
                    {currentRoute.startsWith('/at/') &&
                        <div>
                            <StudioInfoInNav currentRoute={currentRoute} />
                        </div>
                    }
                    <LanguageToggle />
                    {user ?
                        (
                            <div>
                                <UserAvatarWithMenu />
                            </div>
                        )
                        : (
                            <Button style={{ color: 'inherit', textDecoration: 'none' }} onClick={() => navigate('/login')}>
                                <Typography fontSize={{ xs: 12, sm: 18, lg: 24 }} variant="text">
                                    {t('navbar.login')}
                                </Typography>
                            </Button>
                        )
                    }
                </Box>
            </Box>
        </Box >
    );
};
export default Navbar;

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                content: '"文/En"',
                display: 'block',
                textAlign: 'center',
                lineHeight: '32px',
                color: '#fff',
                fontSize: '10px',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&::before': {
            content: '"En/文"',
            display: 'block',
            textAlign: 'center',
            lineHeight: '32px',
            color: '#fff',
            fontSize: '10px',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

export const LanguageToggle = () => {
    const { i18n } = useTranslation();
    const [isChinese, setIsChinese] = useState(i18n.language === 'zh');

    const handleLanguageChange = () => {
        const newLanguage = isChinese ? 'en' : 'zh';
        i18n.changeLanguage(newLanguage);
        setIsChinese(!isChinese);
    };

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const isFirstVisitLanguage = localStorage.getItem('firstVisitLanguage') !== 'true';
        if (isFirstVisitLanguage) {
            localStorage.setItem('firstVisitLanguage', 'true');
            setOpen(true);
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ position: 'relative', display: 'inline-block' }}>
            {/*
            <Button onClick={() => { localStorage.setItem('firstVisitLanguage', null); }}>Reset First Visit</Button>
             TODO remove testing button above
              */}
            <MaterialUISwitch checked={isChinese} onChange={handleLanguageChange} />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        position: 'absolute',
                        top: { xs: '44px', md: '70px' },
                        right: '2px',
                        m: 0,
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            borderWidth: '10px',
                            borderStyle: 'solid',
                            borderColor: 'transparent transparent white transparent',
                        },
                    },
                }}
            >
                <DialogContent sx={{ py: 1, px: 2 }}>
                    <Box display="flex" alignItems="center">
                        <Typography>Switch Language/切换语言</Typography>
                        <MaterialUISwitch checked={isChinese} onChange={handleLanguageChange} />
                        <Button onClick={handleClose} size="small">OK</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
